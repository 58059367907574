import React from 'react';
import Container from '@components/containers/Container';
import DashedRectangle from 'src/assets/images/DashedRectangle.png';
import Pattern from '../../../assets/images/pattern-2.svg';
import Image from 'next/image';
import WysiwygWrapper from '@components/views/TitleDescriptionSection/WysiwygWrapper';
import Button from '../Button';
import { IButtonACF } from 'src/types/IButtonACF';
import Heading, { HeadingProps } from '../Heading';
import { useColors } from 'src/context/colors.context';
import { theme } from '@styles/theme';
import clsx from 'clsx';
import ContainImage from '@components/views/ContainImage';
import { convertPixelsToRems } from 'src/utils/convertPixelsToRems';

export interface TitleDescriptionSectionProps {
  heading: HeadingProps;
  description: string;
  button: IButtonACF;
  subtitle?: string;
  default_decorator: { hide: boolean; width: string; color: string };
  custom_decorator: {
    url: string;
    width: string;
    position: {
      vertical: 'top' | 'bottom' | 'middle';
      horizontal: 'left' | 'right' | 'middle';
    };
  };
}

const TitleDescriptionSection: React.FunctionComponent<
  TitleDescriptionSectionProps
> = (props) => {
  const {
    heading,
    description,
    button,
    subtitle,
    default_decorator = { hide: false, width: '', color: '' },
    custom_decorator = {
      url: '',
      width: '',
      position: { vertical: 'top', horizontal: 'left' },
    },
  } = props;
  const { productColors } = useColors();

  const {
    url: customDecoratorUrl,
    width: customDecoratorWidth,
    position: customDecoratorPosition,
  } = custom_decorator;

  const { hide, width: defaultDecoratorWidth } = default_decorator;

  return (
    <Container className="grid md:grid-cols-2 gap-32 relative">
      {!!customDecoratorUrl && (
        <div
          className={clsx('absolute w-full -z-1', {
            'top-0': customDecoratorPosition.vertical === 'top',
            'bottom-0': customDecoratorPosition.vertical === 'bottom',
            'left-0': customDecoratorPosition.horizontal === 'left',
            'right-0': customDecoratorPosition.horizontal === 'right',
            'left-1/2 transform -translate-x-1/2':
              customDecoratorPosition.horizontal === 'middle',
            'top-1/2 transform -translate-y-1/2':
              customDecoratorPosition.vertical === 'middle',
          })}
          style={{
            maxWidth: `${convertPixelsToRems(customDecoratorWidth)}rem`,
          }}
        >
          <ContainImage src={customDecoratorUrl} />
        </div>
      )}
      <div className="relative md:min-h-[36rem]">
        <div className="absolute w-full h-full flex justify-between items-end">
          <div className="relative w-1/2 h-full">
            <Image layout="fill" src={DashedRectangle} alt="" />
          </div>
          {!hide && (
            <div
              className="max-w-[10rem] md:max-w-[20rem] w-1/2 sm:w-1/3"
              style={{
                width: `${convertPixelsToRems(defaultDecoratorWidth)}rem`,
              }}
            >
              <Pattern
                fill={
                  default_decorator.color ||
                  productColors?.main ||
                  theme.colors.primary.DEFAULT
                }
                stroke={
                  default_decorator.color ||
                  productColors?.main ||
                  theme.colors.primary.DEFAULT
                }
              />
            </div>
          )}
        </div>
        <div className="p-12 lg:p-48 flex flex-col items-start h-full gap-32 relative">
          {!!heading.title && (
            <div className="text-neutral-3 text-3xl md:text-6xl xl:text-7xxl font-light mb-12">
              <Heading {...heading} />
            </div>
          )}
          {!!subtitle && (
            <p
              className="text-lg text-neutral-9 mb-12"
              dangerouslySetInnerHTML={{ __html: subtitle }}
            />
          )}
          {button?.title && (
            <div className="mt-auto">
              <Button {...button} href={button.url}>
                {button.title}
              </Button>
            </div>
          )}
        </div>
      </div>
      <WysiwygWrapper
        className="pt-24 lg:pt-48"
        dangerouslySetInnerHTML={{ __html: description }}
      />
    </Container>
  );
};
export default TitleDescriptionSection;
