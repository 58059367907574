import styled from 'styled-components';
import { theme } from '@styles/theme';

const WysiwygWrapper = styled.div<{ color?: string }>`
  h1,
  h2,
  h3,
  h4,
  h5 {
    color: ${theme.colors.neutral[9]};
    font-weight: 800;
    margin-bottom: 3.2rem;
  }

  h1 {
    font-size: 4.8rem;
  }

  h2 {
    font-size: 4.2rem;
  }

  h3 {
    font-size: 4rem;
  }

  h4 {
    font-size: 3.6rem;
  }

  h5 {
    font-size: 3.2rem;
  }

  ul,
  ol {
    padding-left: 2rem;
  }

  ul {
    list-style: disc;
  }

  ol {
    list-style: decimal;
  }

  p,
  li {
    color: ${({ color }) => color || theme.colors.neutral[5]};
    font-size: 1.8rem;
  }
  p {
    &:not(:last-child) {
      margin-bottom: 3.2rem;
    }
  }

  a {
    color: ${theme.colors.neutral[6]};
    font-size: 1.8rem;
    text-decoration: underline;

    &:hover {
      color: ${theme.colors.neutral[8]};
    }
  }

  blockquote {
    font-style: italic;
    padding: 1.2rem;
    font-size: 1.8rem;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  p {
    span {
      color: ${theme.colors.neutral[0]};
    }
  }
`;

export default WysiwygWrapper;
